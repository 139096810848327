import { AxiosResponse } from 'axios';
import Http from '@/ship/Http';
import { formatToSendAny } from '@/utils/ModelPacking';

// Requests
import { GetRequestParams } from '@/ship/Requests/RequestParams';
import ForgotPasswordRequest from '@/ship/Requests/ForgotPasswordRequest';
import ResetPasswordRequest from '@/ship/Requests/ResetPasswordRequest';
import CreateReportRequest from '@/ship/Requests/CreateReportRequest';
import { ISubmitDrawingData } from '@/ship/Requests/ISubmitDrawingDataRequest';
import CreateDrawingRequest from '@/ship/Requests/CreateDrawingRequest';
import CreateViolationRequest from '@/ship/Requests/CreateViolationRequest';
import CompleteViolationRequest from '@/ship/Requests/CompleteViolationRequest';
import CreatePrescriptionAcceptanceRequest from '@/ship/Requests/CreatePrescriptionAcceptanceRequest';
import CreatePrescriptionWarrantyRequest from '@/ship/Requests/CreatePrescriptionWarrantyRequest';
import CompleteRemarkAcceptanceRequest from '@/ship/Requests/CompleteRemarkAcceptanceRequest';
import CreateRemarkAcceptanceRequest from '@/ship/Requests/CreateRemarkAcceptanceRequest';

import Drawing from '@/ship/Models/Drawing';
import UserEditRequest from '@/ship/Requests/UserEditRequest';
import CityEditRequest from '@/ship/Requests/CityEditRequest';
import OrganisationEditRequest from '@/ship/Requests/OrganisationEditRequest';
import ProjectEditRequest from '@/ship/Requests/ProjectEditRequest';
import EstimateRequest from '@/ship/Requests/EstimateRequest';

// Interfaces
import User from '@/ship/Models/User';
import Role from '@/ship/Models/Role';
import City from '@/ship/Models/City';
import Project from '@/ship/Models/Project';
import Position from '@/ship/Models/Position';
import Section from '@/ship/Models/Section';
import Organisation from '@/ship/Models/Organisation';
import ITransformedValue from '@/ship/Values/ITransformedValue';
import Floor from '@/ship/Models/Floor';
import LoadViolationsFile from '@/ship/Models/LoadViolationsFile';
import Violation from '@/ship/Models/Violation';
import MainPackData from '@/ship/Models/MainPackData';
import ViolationCount from '@/ship/Models/ViolationCount';
import IncludeViolation from '@/ship/Models/IncludeViolation';
import IncludeViolationAcceptance from '@/ship/Models/IncludeViolationAcceptance';
import IncludeViolationWarranty from '@/ship/Models/IncludeViolationWarranty';
import BasicRequirement from '@/ship/Models/BasicRequirement';
import {
    Design,
    DesignCreateModel,
    DesignHistory,
    DesignRemark,
    DesignRemarkGroup,
    DesignTask,
    DesignTaskToSend,
    DesignUpdateModel,
    IDesignFilters,
    IDesignTaskFilters,
} from '@/ship/Models/Design';
import History from '@/ship/Models/History';
import Apartment from '@/ship/Models/Apartment';
import IFile from '@/ship/Models/IFile';
import { IChat } from '@/ship/Models/Chats';
import ICitiesWithProjects from '@/ship/Models/ICitiesWithProjects';
import { IReplacement, IReplacementEditModel } from '@/ship/Models/IReplacement';
import {
    EstimateType,
    IEstimate,
    IEstimateAct,
    IEstimateAdditional,
    IEstimateFilterOptions,
    IEstimatePositionUpdateModel,
    IEstimateProject,
    IEstimateUpdateModel,
} from '@/ship/Models/IEstimate';
import { UserNotificationsSettings } from '@/ship/Models/UserNotificationsSettings';
import { FiltersAcceptance } from '@/ship/Models/Acceptance';
import { FiltersWarranty } from '@/ship/Models/IPrescriptionFilterWarranty';
import { Dictionary } from 'lodash';

import {
    IPMChart,
    IPMChartBpc,
    IPMChartDpc,
    IPMChartDpcExec,
    IPMChartDpcExecTask,
    IPMChartHistory,
    IPMChartVersion,
    IPMCity,
    IPMProject,
    IPMReportDeadlinesTask,
} from '@/ship/Models/IProjectManagement';

import { EstimateUserTypeEnum } from '@/ship/Enums/EstimateEnums';

import { ChartVersion, CreateTaskStructureBody, Structure, TemplateStructure } from '@/ship/Models/ProjectManagement';

export const createTemporaryFile = async (file: File): Promise<IFile> => {
    const formData = new FormData();
    formData.append('file', file);

    const response = await Http.post<void, AxiosResponse<ITransformedValue<IFile>>>(`/temporary-file`, formData);

    return response.data.data;
};

export const getPrescriptionHomeCitiesWithProjects = async () => {
    return (await Http.get<ITransformedValue<ICitiesWithProjects[]>>(`/profile/cities-with-projects`)).data.data;
};

export const getUserProfile = (): Promise<AxiosResponse<ITransformedValue<User>>> => {
    return Http.get(`/user/profile`, { params: { include: 'roles,permissions,projects,cities' } });
};

export const changeUserSettings = async (settings: { isSnow: boolean }) => {
    return Http.post(`/user/change/settings`, { ...settings });
};

export const checkUserUniqueEmail = async (email: string) =>
    (await Http.post<{ email: boolean }>('/user/check/unique/', { email })).data;

export const getUser = <D = User>(id: number, params?: GetRequestParams) =>
    Http.get<ITransformedValue<D>>(`/users/${id}`, { params });

export const getUsers = <D = User>(params?: GetRequestParams) => Http.get<ITransformedValue<D[]>>(`/users`, { params });

export const getFilteredUsers = <D = User>(params: GetRequestParams = { include: 'roles', limit: 0 }) =>
    Http.get<ITransformedValue<D[]>>(`/users/get/filtered`, { params });

export const deleteUser = (id: number): Promise<AxiosResponse> => {
    return Http.delete(`/users/${id}`);
};

export const updateUser = (id: number, data: UserEditRequest): Promise<AxiosResponse<ITransformedValue<User>>> => {
    return Http.post(`/users/${id}`, { id, ...data });
};

export const createUser = (data: UserEditRequest) => Http.post<ITransformedValue<User>>(`/register`, data);

export const forgotUserPassword = (data: ForgotPasswordRequest): Promise<AxiosResponse<ITransformedValue<User>>> => {
    return Http.post(`/password/forgot`, data);
};

export const resetUserPassword = (data: ResetPasswordRequest): Promise<AxiosResponse<ITransformedValue<User>>> => {
    return Http.post(`/password/reset`, data);
};

/**
 * User replacement API
 */

export const getReplacements = (
    params?: GetRequestParams,
): Promise<AxiosResponse<ITransformedValue<IReplacement[]>>> => {
    return Http.get(`affil/users`, { params });
};

export const getReplacement = (id: number | string): Promise<AxiosResponse<ITransformedValue<IReplacement>>> => {
    return Http.get(`affil/users/${id}`);
};

export const removeReplacement = (id: number | string): Promise<AxiosResponse<ITransformedValue<IReplacement>>> => {
    return Http.get(`affil/users/${id}/delete`);
};

export const createReplacement = (
    data: IReplacementEditModel,
): Promise<AxiosResponse<ITransformedValue<IReplacement>>> => {
    return Http.post(`affil/users/by-id`, data);
};

export const updateReplacement = (
    id: number | string,
    data: IReplacementEditModel,
): Promise<AxiosResponse<ITransformedValue<IReplacement>>> => {
    return Http.post(`affil/users/${id}`, data);
};

export const endReplacement = (id: number | string): Promise<AxiosResponse<ITransformedValue<IReplacement>>> => {
    return Http.get(`affil/users/${id}/end`);
};

export const getAllBasicRequirement = (): Promise<AxiosResponse<ITransformedValue<BasicRequirement[]>>> => {
    return Http.get(`/basicrequirements?limit=0`);
};

/**
 * Violations / Prescriptions
 */
export const updateViolation = (id: number, data: CreateViolationRequest) => {
    return Http.post(`/violations/${id}`, data);
};

export const getViolations = (
    page: number,
    search: string,
): Promise<AxiosResponse<ITransformedValue<IncludeViolation[]>>> => {
    return Http.get(`/violations?search=${search}&searchJoin=and&orderBy=id&sortedBy=desc&page=${page}`);
};

export const getAllTypesViolations = (
    page: number,
    search: string,
): Promise<AxiosResponse<ITransformedValue<IncludeViolation[]>>> => {
    return Http.get(`/all-types-violations?search=${search}&searchJoin=and&orderBy=id&sortedBy=desc&page=${page}`);
};

export const getViolationById = (id: number): Promise<AxiosResponse<ITransformedValue<IncludeViolation>>> => {
    return Http.get(`/violations/${id}`);
};

export const getViolationsAcceptance = (
    page: number,
    search: string,
): Promise<AxiosResponse<ITransformedValue<IncludeViolationAcceptance[]>>> => {
    return Http.get(
        `/violations-acceptance?search=${search}&searchJoin=and&orderBy=id&sortedBy=desc&page=${page}&include=tasks`,
    );
};

export const getAllViolationsAcceptance = (
    search: string,
): Promise<AxiosResponse<ITransformedValue<IncludeViolationAcceptance[]>>> => {
    return Http.get(`/violations-acceptance?search=${search}&searchJoin=and&orderBy=id&sortedBy=desc&limit=0`);
};

export const getViolationsAcceptanceById = (
    id: number,
): Promise<AxiosResponse<ITransformedValue<IncludeViolationAcceptance>>> => {
    return Http.get(
        `/violations-acceptance/${id}?include=tasks.photo,general_contractor,customer,project,tasks.completes,tasks.completes.photo,tasks.completes.document,document`,
    );
};

export const getFiltersAcceptance = (): Promise<AxiosResponse<FiltersAcceptance>> => {
    return Http.get(`/violations-acceptance/get/filters`);
};

export const getFiltersWarranty = (): Promise<AxiosResponse<FiltersWarranty>> => {
    return Http.get(`/violation-guarantees/get/filters`);
};

export const getPrescriptionHistory = <D = History>(id: number, params?: GetRequestParams) =>
    Http.get<ITransformedValue<D[]>>(`/violations/${id}/history`, { params });

export const getPrescriptionHistoryAcceptance = <D = History>(id: number, params?: GetRequestParams) =>
    Http.get<ITransformedValue<D[]>>(`/violations-acceptance/${id}/history`, { params });

export const getPrescriptionHistoryWarranty = <D = History>(id: number, params?: GetRequestParams) =>
    Http.get<ITransformedValue<D[]>>(`/violation-guarantees/${id}/history`, { params });

export const getFloorById = (id: number): Promise<AxiosResponse<ITransformedValue<Floor>>> => {
    return Http.get(`/floors/${id}?include=apartments`);
};

export const getViolationsWarranty = (
    page: number,
    search: string,
): Promise<AxiosResponse<ITransformedValue<IncludeViolationWarranty[]>>> => {
    return Http.get(`/violation-guarantees?search=${search}&searchJoin=and&orderBy=id&sortedBy=desc&page=${page}`);
};

export const getViolationsWarrantyById = (
    id: number,
): Promise<AxiosResponse<ITransformedValue<IncludeViolationWarranty>>> => {
    return Http.get(
        `/violations-guarantees/${id}?include=general_contractor,customer,project,act,subcontractor,executors`,
    );
};

export const createPrescriptionWarranty = (id: number) => {
    return Http.get(`/violation-guarantees/${id}/to-work`);
};

export const updatePrescriptionWarranty = (data: CreatePrescriptionWarrantyRequest) => {
    return Http.put(`/violation-guarantees/${data.id}`, data);
};

export const completePrescriptionWarranty = (data: { id: number; description: string; photo_ids: number[] }) => {
    return Http.put(`/violation-guarantees/${data.id}/complete?include=act,subcontractor,executors`, data);
};

export const changeExecutorWarranty = (data: {
    id: number;
    executors_ids?: number[];
    subcontractor_id?: number | null;
}) => {
    return Http.put(`/violation-guarantees/${data.id}/change/executors`, data);
};

/**
 * Users notifications setting API
 */
export const getUserNotificationsSettings = () => {
    return Http.get<UserNotificationsSettings>(`/user/get/notification-settings`);
};

export const setUserNotificationsSettings = (params: UserNotificationsSettings) => {
    return Http.post(`/user/set/notification-settings`, params);
};

/**
 * Gets packed data for prescriptions
 */
export const getMainPackData = (): Promise<AxiosResponse<MainPackData>> => {
    return Http.get(`/get_main_pack_data`);
};

export const getGeneralContractorOrganisationById = (
    id: number,
): Promise<AxiosResponse<ITransformedValue<Organisation>>> => {
    return Http.get(`/organisations/${id}?include=subcontractors,users`);
};

export const getViolationStatuses = (data: { cities: number[] }): Promise<AxiosResponse<ViolationCount>> => {
    return Http.post(`/cities/violations/count`, data);
};

export const setStatusAccept = (id: number) => {
    return Http.get(`/violations/${id}/accept`);
};

export const setStatusAcceptSubtask = (ids: { violationId: number; taskId: number }) => {
    return Http.get(`/violations/${ids.violationId}/task/${ids.taskId}/accept`);
};

export const rejectStatus = (id: number, description: { description: string }) => {
    return Http.post(`/violations/${id}/inWork`, description);
};

export const rejectStatusSubtask = (
    ids: { violationId: number; taskId: number },
    data: { description: string; photoId: number[] },
) => {
    return Http.post(`/violations/${ids.violationId}/task/${ids.taskId}/inWork`, data);
};

export const postponementApproval = (id: number, data: { deadline: string; description: string }) => {
    return Http.post(`/violations/${id}/toPostponementApproval`, data);
};

export const postponementApprovalAccept = (id: number, postponementApprovalId: number) => {
    return Http.get(`/violations/${id}/postponementApproval/${postponementApprovalId}/accept`);
};

/* Create data */

export const createDrawing = (data: CreateDrawingRequest): Promise<AxiosResponse<{ data: Drawing }>> => {
    return Http.post(`/drawings`, data);
};

export const createReports = (data: CreateReportRequest) => {
    return Http.post(`/violations`, data);
};

export const createViolation = (data: CreateViolationRequest) => {
    return Http.post(`/violations`, data);
};

export const createLoadViolationsFile = (
    data: FormData,
): Promise<AxiosResponse<ITransformedValue<LoadViolationsFile>>> => {
    return Http.post(`/violations/photo/load`, data);
};

export const createForm = (id: number): Promise<AxiosResponse<ITransformedValue<string>>> => {
    return Http.get(`/violations/${id}/pdf`);
};

export const formViolationAcceptance = (id: number): Promise<AxiosResponse<ITransformedValue<string>>> => {
    return Http.get(`violations-acceptance/${id}/act`);
};

export const createPrescriptionAcceptance = (data: CreatePrescriptionAcceptanceRequest) => {
    return Http.post(`/violations-acceptance`, data);
};

export const completeRemarkAcceptance = (data: CompleteRemarkAcceptanceRequest) => {
    return Http.post(
        `/violations-acceptance/task/${data.task_id}/complete?include=tasks.completes,tasks.completes.photo,tasks.completes.document,tasks.photo,general_contractor`,
        data,
    );
};

export const changeExecutorRemarkAcceptance = (data: {
    task_id: number;
    executors_ids?: number[];
    subcontractor_id?: number | null;
}) => {
    return Http.put('/violations-acceptance/change/executors', data);
};

export const createRemarkAcceptance = (data: CreateRemarkAcceptanceRequest) => {
    return Http.post(`/violations-acceptance/task`, data);
};

export const updateRemarkAcceptance = (data: CreateRemarkAcceptanceRequest) => {
    return Http.put(`/violations-acceptance/task/${data.id}`, data);
};

export const changeDocumentAcceptance = (data: { violation_id: number; document_id: number }) => {
    return Http.post(`/violations-acceptance/change/document?include=document`, data);
};

export const cancelRemarkAcceptance = (id: number) => {
    return Http.post(
        `/violations-acceptance/task/${id}/cancel?include=tasks.completes,tasks.completes.photo,tasks.completes.document,tasks.photo,general_contractor`,
    );
};

export const changeDeadlineAcceptance = (data: { violation_id: number; deadline: string }) => {
    return Http.post(`violations-acceptance/change/deadline`, data);
};

export const acceptTypeOfRemarkAcceptance = (id: number) => {
    return Http.get(
        `violations-acceptance/accept-type/${id}?include=tasks.completes,tasks.completes.photo,tasks.completes.document,tasks.photo,general_contractor`,
    );
};

export const cancelTypeOfRemarkAcceptance = (id: number) => {
    return Http.get(
        `violations-acceptance/cancel-type/${id}?include=tasks.completes,tasks.completes.photo,tasks.completes.document,tasks.photo,general_contractor`,
    );
};

export const acceptRemarkAcceptance = (id: number) => {
    return Http.get(
        `violations-acceptance/task/${id}/accept?include=tasks.completes,tasks.completes.photo,tasks.completes.document,tasks.photo,general_contractor`,
    );
};

export const rejectRemarkAcceptance = (id: number) => {
    return Http.get(
        `violations-acceptance/task/${id}/reject?include=tasks.completes,tasks.completes.photo,tasks.completes.document,tasks.photo,general_contractor`,
    );
};

export const getTypicalRemarksAcceptance = (data: { typeName: string }) => {
    return Http.post(`/violations/get/descriptions`, data);
};

/* Complete Violation */

export const violationComplete = (data: CompleteViolationRequest) => {
    return Http.post(`/violations/${data.id}/complete`, data);
};

export const subtaskComplete = (
    id: { violationId: number; taskId: number },
    data: { description: string; photoId: number[] },
) => {
    return Http.post(`/violations/${id.violationId}/task/${id.taskId}/complete`, data);
};

export const violationInWork = (id: string) => {
    return Http.post(`/violations/${id}/inWork`);
};

/* Search Violations */

export const searchViolation = (id: number): Promise<AxiosResponse<Violation>> => {
    return Http.get(`/violations/?search=id:${id}`);
};

/**
 * Change executors and subcontructor
 *
 * Access: Authenticated Customer/General Constructor
 */
export const changeExecutors = (
    id: number,
    data: { executorsIds?: number[]; executorId?: number; subcontractorId?: number },
) => {
    return Http.post(`/violations/${id}/change/executors`, data);
};

/* delete violation */

export const deleteViolation = (id: number) => {
    return Http.delete(`/violations/${id}`);
};

// Drawings
export const submitDrawingFile = async (drawing: File): Promise<number> => {
    const formData = new FormData();
    formData.append('file', drawing);

    const response = await Http.post<void, AxiosResponse<ITransformedValue<{ id: number }>>>(
        `/drawings/file`,
        formData,
    );

    return response.data.data.id;
};

export const submitDrawingData = (fileId: number, data: ISubmitDrawingData[]): Promise<void> => {
    return Http.post(`/drawings`, { fileId, data });
};

export const getOfferURL = (): Promise<AxiosResponse<string>> => Http.get(`/offer`);

export const getPriceURL = (): Promise<AxiosResponse<string>> => Http.get(`/price`);

export const getAllRoles = <D = Role>(params?: GetRequestParams) =>
    Http.get<ITransformedValue<D[]>>(`/roles`, { params });

export const getAllPositions = (): Promise<AxiosResponse<ITransformedValue<Position[]>>> => {
    return Http.get(`/positions`);
};

// Projects Api

export const getProject = <D = Project>(id: number, params?: GetRequestParams) =>
    Http.get<ITransformedValue<D>>(`/projects/${id}`, { params });

export const getAllProjects = <D = Project>(params: GetRequestParams) =>
    Http.get<ITransformedValue<D[]>>(`/projects`, { params });

export const createProject = async (data: ProjectEditRequest): Promise<Project> => {
    const response = await Http.post<void, AxiosResponse<ITransformedValue<Project>>>(`/projects`, data);

    return response.data.data;
};

export const updateProject = async (id: number, data: ProjectEditRequest, include?: string): Promise<Project> => {
    const response = await Http.patch<void, AxiosResponse<ITransformedValue<Project>>>(
        `/projects/${id}`,
        { id, ...data },
        { params: { include } },
    );

    return response.data.data;
};

export const changeProjectImage = async (projectId: number, imageId: number) => {
    const response = await Http.get<void, AxiosResponse<ITransformedValue<{ fileUrl: string }>>>(
        `/projects/${projectId}/change/${imageId}`,
    );

    return response.data.data;
};

export const getProjectByGuid = <D = Project>(guid: string) =>
    Http.get<ITransformedValue<D>>(`/projects/guid/${guid}?include=generalContractor,customer`);

export const getProjectByCode = <D = Project>(code: string) =>
    Http.get<ITransformedValue<D>>(`/projects/code/${code}?include=generalContractor,customer`);

// Sections Api

export const getSection = <D = Section>(id: number, params?: GetRequestParams) =>
    Http.get<ITransformedValue<D>>(`/sections/${id}`, { params });

export const getAllSections = <D = Section>(params?: GetRequestParams) =>
    Http.get<ITransformedValue<D[]>>(`/sections`, { params });

export const createSection = (name: string, projectId: number): Promise<AxiosResponse<ITransformedValue<Section>>> => {
    return Http.post(`/sections`, { name, projectId });
};

export const updateSection = (
    id: number,
    name?: string | null,
    projectId?: number | null,
): Promise<AxiosResponse<ITransformedValue<Section>>> => {
    return Http.patch(`/sections/${id}`, { id, name, projectId });
};

// Floors Api
export const getFloor = <D = Floor>(id: number, params?: GetRequestParams) =>
    Http.get<ITransformedValue<D>>(`floors/${id}`, { params });

export const getAllFloors = <D = Floor>(params?: GetRequestParams) =>
    Http.get<ITransformedValue<D[]>>(`/floors`, { params });

export const createFloor = (name: string, sectionId: number): Promise<AxiosResponse<ITransformedValue<Floor>>> => {
    return Http.post(`/floors`, { name, sectionId });
};

export const updateFloor = (
    id: number,
    name?: string | null,
    sectionId?: number | null,
): Promise<AxiosResponse<ITransformedValue<Floor>>> => {
    return Http.patch(`/floors/${id}`, { id, name, sectionId });
};

// Apartments Api
export const getApartment = <D = Apartment>(id: number, params?: GetRequestParams) =>
    Http.get<ITransformedValue<D>>(`apartments/${id}`, { params });

export const getAllApartments = <D = Apartment>(params?: GetRequestParams) =>
    Http.get<ITransformedValue<D[]>>(`/apartments`, { params });

export const createApartment = (
    name: string,
    floorId: number,
): Promise<AxiosResponse<ITransformedValue<Apartment>>> => {
    return Http.post(`/apartments`, { name, floorId });
};

export const updateApartment = (
    id: number,
    name?: string | null,
    floorId?: number | null,
): Promise<AxiosResponse<ITransformedValue<Apartment>>> => {
    return Http.patch(`/apartments/${id}`, { id, name, floorId });
};

// Organisation Api

export const addGeneralContractorToCustomer = async (
    generalContractorId: number,
    customerId: number,
): Promise<Organisation> => {
    const response = await Http.post<void, AxiosResponse<ITransformedValue<Organisation>>>(
        `/organisations/customer/general_contractor/add`,
        { customerId, generalContractorId },
    );

    return response.data.data;
};

export const deleteGeneralContractorToCustomer = async (
    generalContractorId: number,
    customerId: number,
): Promise<void> => {
    await Http.post<void, AxiosResponse>(
        `/organisations/customer/${customerId}/general_contractor/${generalContractorId}/delete`,
    );
};

export const addSubcontractorToGeneralContractor = async (
    generalContractorId: number,
    subcontractorId: number,
): Promise<Organisation> => {
    const response = await Http.post<void, AxiosResponse<ITransformedValue<Organisation>>>(
        `/organisations/general_contractor/subcontractor/add`,
        { generalContractorId, subcontractorId },
    );

    return response.data.data;
};

export const deleteSubcontractorToGeneralContractor = async (
    generalContractorId: number,
    subcontractorId: number,
): Promise<void> => {
    await Http.post<void, AxiosResponse>(
        `/organisations/general_contractor/${generalContractorId}/subcontractor/${subcontractorId}/delete`,
    );
};

/**
 * Binds new users to organisation
 * @param organisationId Updating organisation id (null for deleting)
 * @param users List of user ids
 */
export const changeUsersInOrganisation = (organisationId: number | null, users: number[]) => {
    return Http.post<ITransformedValue<Organisation>>(`/organisations/users/change`, { organisationId, users });
};

/**
 * Get only one organisation by id.
 * Permission: search-organisations
 *
 * @param id Organisation id
 * @param params Optional get request parametrs
 */
export const getAnOrganisation = (id: number, params?: GetRequestParams) => {
    return Http.get<ITransformedValue<Organisation>>(`/organisations/${id}?include=users`, { params });
};

export const getAllOrganisations = <D = Organisation>(params?: GetRequestParams) =>
    Http.get<ITransformedValue<D[]>>(`/organisations?include=subcontractors`, { params });

export const createOrganisation = async (data: OrganisationEditRequest) => {
    const response = await Http.post<ITransformedValue<Organisation>>(`/organisations`, data);
    return response.data.data;
};

export const updateOrganisation = async (id: number, data: OrganisationEditRequest) => {
    const response = await Http.patch<ITransformedValue<Organisation>>(`/organisations/${id}`, {
        id,
        ...data,
    });

    return response.data.data;
};

export const checkUniqueOrganisationData = async (data: { INN?: number; contact_number?: string }) => {
    return (await Http.post<{ INN: boolean | null; phoneNumber: boolean | null }>('/organisations/check/unique/', data))
        .data;
};

export const findOrganisationByINN = async (inn: string): Promise<Organisation> => {
    const response = await Http.get<void, AxiosResponse<ITransformedValue<Organisation>>>(`/organisations/inn/${inn}`);

    return response.data.data;
};

// City Api

export const getCity = (id: number, params?: GetRequestParams) =>
    Http.get<ITransformedValue<City>>(`/cities/${id}`, { params });

export const getAllCities = <D = City>(params?: GetRequestParams) =>
    Http.get<ITransformedValue<D[]>>(`/cities`, { params });

export const createCity = (data: CityEditRequest): Promise<AxiosResponse<ITransformedValue<City>>> => {
    return Http.post(`/cities`, data);
};

export const updateCity = (id: number, data: Partial<CityEditRequest>) =>
    Http.post<ITransformedValue<City>>(`/cities/${id}`, data);

/* Designs */
// Design
export const getDesigns = async (params?: GetRequestParams) => {
    const response = await Http.get<ITransformedValue<Design[]>>(`/designs`, { params });
    return response.data;
};

export const getDesign = (id: number | string): Promise<AxiosResponse<ITransformedValue<Design>>> =>
    Http.get<ITransformedValue<Design>>(
        `/designs/${id}?include=project.users,project.generalDesigners,drawings,author`,
    );

export const deleteDesign = (id: number): Promise<AxiosResponse> => Http.get(`/designs/${id}/delete`);

export const deleteDesigns = (ids: number[]): Promise<AxiosResponse> => Http.post(`/designs/multi/delete`, { ids });

export const updateDesign = (id: number, data: DesignUpdateModel): Promise<AxiosResponse> =>
    Http.post(`/designs/${id}/change`, formatToSendAny(data, ['deadline']));

export const acceptDesign = (id: number): Promise<AxiosResponse> => Http.get(`/designs/${id}/accept`);

export const toWorkDesign = (id: number): Promise<AxiosResponse> => Http.get(`/designs/${id}/to-work`);

export const returnCompletedDesign = (id: number): Promise<AxiosResponse> =>
    Http.get(`/designs/${id}/complete-without-file`);

/**
 * Отправить чертёж по проектированию в производство
 */
export const toProductionDesign = (id: number) => Http.get(`/designs/${id}/to-production`);

/**
 * Отправить несколько чертежей по проектированию в производство
 */
export const toProductionSeveralDesigns = (ids: number[]) => Http.post(`/designs/change/to-production`, { ids });

export const createDesign = (data: DesignCreateModel): Promise<AxiosResponse<ITransformedValue<Design>>> =>
    Http.post(`/designs`, formatToSendAny(data));

export const createDesigns = (data: DesignCreateModel[]): Promise<AxiosResponse<ITransformedValue<Design[]>>> =>
    Http.post(`/designs/multi`, { data: formatToSendAny(data) });

export const changeDesignExecutor = (designId: number, executorId: number): Promise<AxiosResponse> =>
    Http.get(`/designs/${designId}/change/executor/${executorId}`);

// Design group
export const updateDesignGroup = (group: DesignRemarkGroup): Promise<AxiosResponse> =>
    Http.post(`/designs/group/${group.id}`, formatToSendAny(group));

export const deleteDesignGroup = (id: number): Promise<AxiosResponse> => Http.get(`/designs/group/${id}/delete`);

// Design remark
export const createDesignRemark = (
    designId: number,
    remark: DesignRemark,
): Promise<AxiosResponse<ITransformedValue<DesignRemarkGroup>>> => Http.post(`/designs/${designId}/task/`, remark);

export const getDesignRemark = async (remarkId: number): Promise<DesignRemark> =>
    (await Http.get(`/designs/task/${remarkId}`)).data.data;

export const updateDesignRemark = (remark: DesignRemark): Promise<AxiosResponse> =>
    Http.post(`/designs/task/${remark.id}`, remark);

export const deleteDesignRemark = (id: number): Promise<AxiosResponse> => Http.get(`/designs/task/${id}/delete`);

export const changeDesignRemarkGroup = (remarkId: number, groupId: number): Promise<AxiosResponse> =>
    Http.get(`/designs/task/${remarkId}/change/group/${groupId}`);

export const attachFilesToRemark = <D = DesignRemark>(id: number, fileIds: number[], params?: GetRequestParams) =>
    Http.post<ITransformedValue<D>>(`/designs/task/${id}/files`, { fileIds }, { params });

export const removeFilesFromRemark = <D = DesignRemark>(id: number, fileIds: number[], params?: GetRequestParams) =>
    Http.post<ITransformedValue<D>>(`/designs/task/${id}/files/delete`, { fileIds }, { params });

// Design task
export const createDesignTask = (task: DesignTaskToSend): Promise<AxiosResponse<ITransformedValue<DesignTask>>> =>
    Http.post(`/designs/author-task`, formatToSendAny(task, ['deadline']), {
        params: { include: 'files,author,authors.affiledBy,executors.affiledBy,changeDeadline' },
    });

export const updateDesignTask = (task: DesignTaskToSend): Promise<AxiosResponse<ITransformedValue<DesignTask>>> =>
    Http.post(`/designs/author-task/${task.id}`, formatToSendAny(task, ['deadline']), {
        params: { include: 'files,author,authors.affiledBy,executors.affiledBy,changeDeadline' },
    });

export const getDesignTasksById = async (designId: number) =>
    (
        await Http.get<ITransformedValue<DesignTask[]>>(`/designs/${designId}/author-tasks`, {
            params: {
                include: 'design,files,author,authors.affiledBy,executors.affiledBy,changeDeadline',
                sortedBy: 'desc',
            },
        })
    ).data.data;

export const getDesignTasksNames = async (name: string) =>
    (await Http.post<ITransformedValue<string[]>>(`/designs/find-author-tasks-names`, { name })).data.data;

export const uploadDesignTaskFile = async (params: { file: File; process: number }): Promise<IFile> => {
    const formData = new FormData();
    formData.append('file', params.file);
    const config = {
        onUploadProgress: (progressEvent: { loaded: number; total: number }) =>
            (params.process = Math.round((progressEvent.loaded * 100) / progressEvent.total)),
    };

    const response = await Http.post(`/designs/load/author-task/file`, formData, config);
    return response.data.data;
};

export const getAllDesignTasks = <D = DesignTask>(params?: GetRequestParams) =>
    Http.get<ITransformedValue<D[]>>(`/designs/get/author-tasks`, { params });

export const getAllAffiledDesignTasks = <D = DesignTask>(params?: GetRequestParams) =>
    Http.get<ITransformedValue<D[]>>(`/designs/get/author-tasks/affiled`, { params });

export const getDesignTask = <D = DesignTask>(id: number, params?: GetRequestParams) =>
    Http.get<ITransformedValue<D>>(`/designs/author-task/${id}`, { params });

export const attachFilesToDesignTask = <D = DesignTask>(id: number, fileIds: number[], params?: GetRequestParams) =>
    Http.post<ITransformedValue<D>>(`/designs/author-task/${id}/files`, { fileIds }, { params });

export const removeFilesFromDesignTask = <D = DesignTask>(id: number, fileIds: number[], params?: GetRequestParams) =>
    Http.post<ITransformedValue<D>>(`/designs/author-task/${id}/files/delete`, { fileIds }, { params });

// Design filters
export const getDesignFilters = async () => (await Http.get<IDesignFilters>('/designs/filters/get')).data;

// Tasks filters
export const getTasksFilters = async () => (await Http.get<IDesignTaskFilters>('/designs/tasks/filters/get')).data;

// Design changelog
export const getDesignHistory = <D = DesignHistory>(designId: number, params?: GetRequestParams) =>
    Http.get<ITransformedValue<D[]>>(`/designs/${designId}/history`, { params });

export const getDesignTaskHistory = <D = DesignHistory>(taskId: number, params?: GetRequestParams) =>
    Http.get<ITransformedValue<D[]>>(`/designs/tasks/${taskId}/history`, { params });

// Design image
/**
 * Initialization for parsing pdf to images
 * @param params documentSecret - drawing uid, url - drawing url in tcloud
 * @param headers auth
 * @returns documentSecret - drawing uid, pagesCount - number of pages
 */
export const postDesignPageImage = <D = { documentSecret: string; pagesCount: number }>(
    params: { documentSecret: string; url: string },
    headers?: Dictionary<string>,
) => Http.post<D>(`/pdf-reader/export-pdf`, params, headers);

/**
 * Get image url for page
 * @param params documentSecret - drawing uid, page - page number
 * @returns url - image url for page
 */
export const getDesignPageImageUrl = async (documentSecret: string, page: number = 1): Promise<string> => {
    const response = await Http.get(`/pdf-reader/get-page/${documentSecret}/${page}`, { responseType: 'blob' });
    return URL.createObjectURL(response.data);
};

/* Chats */
// Chats
export const readChatMessages = (chatId: number): Promise<AxiosResponse<ITransformedValue<IChat>>> =>
    Http.get(`/chat/${chatId}/read`);

// Estimates
export const getAllEstimates = async (params?: GetRequestParams) => {
    const response = await Http.get<ITransformedValue<IEstimate[]>>(`/smeta`, { params });
    return response.data;
};

export const getListActs = async (params?: GetRequestParams) => {
    const response = await Http.get<ITransformedValue<IEstimate[]>>(`/acts`, { params });
    return response.data;
};

export const getEstimate = async (id: number, params?: GetRequestParams) => {
    const response = await Http.get<ITransformedValue<IEstimate>>(`/smeta/${id}`, { params });
    return response.data.data;
};

export const getAdditionalEstimate = async (id: number, params?: GetRequestParams) => {
    const response = await Http.get<ITransformedValue<IEstimateAdditional>>(`/additional-smeta/${id}`, { params });
    return response.data.data;
};

export const getAct = async (id: number, params?: GetRequestParams) => {
    const response = await Http.get<ITransformedValue<IEstimateAct>>(`/act/${id}`, { params });
    return response.data.data;
};

// Estimate filters options
export const getEstimateFiltersOptions = async () =>
    (await Http.get<IEstimateFilterOptions>('/smeta/get/filters')).data;

/**
 * Delete add.estimate / act;
 * Return:
 * parentId - id parent estimate
 * parentType - type of estimate (1: est, 2: add.est)
 */
export const deleteEstimate = async (id: number) => {
    const response = await Http.get<ITransformedValue<{ parentId: number; parentType: number }>>(
        `/estimate/${id}/delete`,
    );
    return response.data.data;
};
export const createEstimate = async (data: EstimateRequest) => {
    const response = await Http.post<ITransformedValue<IEstimate | IEstimateAdditional | IEstimateAct>>(
        `/estimate`,
        data,
    );
    return response.data.data;
};

export const changeVat = (id: number, data: { withoutVat: boolean }) => {
    Http.post(`/estimate/${id}/change-vat`, data);
};

/**
 * Uploads an Excel estimate file.
 *
 * @param file - The Excel file to upload.
 * @param id - The ID of the estimate to import the file into.
 * @returns A promise that resolves to an object representing the uploaded file.
 */
export const uploadExcelEstimate = async (file: File, id: number) => {
    // Create a new form data object and append the file to it.
    const formData = new FormData();
    formData.append('file', file);

    // Send a POST request with the form data to the server.
    const response = await Http.post(`estimate/${id}/import`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

    // Return the data from the response.
    return response.data;
};

export const getEstimateProjects = async (params?: GetRequestParams) => {
    const response = await Http.get<ITransformedValue<IEstimateProject[]>>(`/estimates-count-by-projects`, { params });
    return response.data.data;
};

export const sendEstimateForReview = async (id: number) => {
    const response = await Http.get<ITransformedValue<IEstimate>>(`estimate/${id}/to-checking`);
    return response.data.data;
};

export const sendEstimateForRevision = async (
    id: number,
    role: EstimateUserTypeEnum = EstimateUserTypeEnum.Estimator,
) => {
    const tail = 'to-fixing' + getApiTail(role);
    const response = await Http.get<ITransformedValue<IEstimate>>(`estimate/${id}/${tail}`);
    return response.data.data;
};

export const sendEstimateForApproval = async (
    id: number,
    role: EstimateUserTypeEnum = EstimateUserTypeEnum.Estimator,
) => {
    const tail = 'agree' + getApiTail(role);
    const response = await Http.get<ITransformedValue<IEstimate>>(`estimate/${id}/${tail}`);
    return response.data.data;
};

export const sendEstimateForCancellation = async (
    id: number,
    role: EstimateUserTypeEnum = EstimateUserTypeEnum.Estimator,
) => {
    const tail = 'cancel' + getApiTail(role);
    const response = await Http.get<ITransformedValue<IEstimate>>(`estimate/${id}/${tail}`);
    return response.data.data;
};

const getApiTail = (role: EstimateUserTypeEnum): string => {
    let tail = '';
    switch (role) {
        case EstimateUserTypeEnum.Supervisor:
            tail = '-by-supervisor';
            break;

        case EstimateUserTypeEnum.ProjectManager:
            tail = '-by-project-manager';
            break;

        default:
            break;
    }
    return tail;
};

export const updateEstimate = async (id: number, data: IEstimateUpdateModel) => {
    const response = await Http.post<ITransformedValue<IEstimate | IEstimateAdditional | IEstimateAct>>(
        `/estimate/${id}`,
        data,
    );
    return response.data.data;
};

export const updateEstimateDate = async (id: number, data: { date: string }) => {
    const response = await Http.post<ITransformedValue<EstimateType>>(`/estimate/${id}/change-date`, data);
    return response.data.data;
};

export const sendEstimateForSync = async (id: number) => {
    const response = await Http.get<ITransformedValue<IEstimate>>(`estimate/${id}/1c/send`);
    return response.data.data;
};

export const uploadFileForEstimate = async (params: { file: File; process: number }): Promise<IFile> => {
    const formData = new FormData();
    formData.append('file', params.file);
    const config = {
        onUploadProgress: (progressEvent: { loaded: number; total: number }) =>
            (params.process = Math.round((progressEvent.loaded * 100) / progressEvent.total)),
    };

    const response = await Http.post(`/estimates/load/file`, formData, config);
    return response.data.data;
};

export const syncFilesForEstimate = <D = EstimateType>(id: number, fileIds: number[], params?: GetRequestParams) =>
    Http.post<ITransformedValue<D>>(`/estimate/${id}/sync/files`, { fileIds }, { params });

/************
 * PM Chart *
 ************/
// Получить список графиков
export const getPMChartList = (params?: GetRequestParams) => {
    return Http.get<ITransformedValue<IPMChart[]>>(`/project-management/charts`, { params });
};

// Создать график
export const createPMChart = (data: {
    stage: string;
    name: string;
    managerId: number | null;
    designerId: number | null;
    calendarType: number | null;
    projectId: number | null;
    startedAt: string;
    address?: string;
    contract?: string;
    permissionNumber?: string;
    chiefEngineerId?: number | null;
    permissionDate?: string;
    regionalHeadId?: number | null;
    permissionProlongation?: string;
    directorId?: number | null;
    generalContractorId?: number | null;
}) => {
    return Http.post<ITransformedValue<IPMChart>>(`/project-management/charts`, { ...data });
};

/* * Get only one chart by id.
 * Permission: search-charts
 *
 * @param id Chart id
 * @param params Optional get request parameters
 */
export const getPMChart = (id: number, params?: GetRequestParams) => {
    return Http.get<ITransformedValue<IPMChart>>(`/project-management/charts/${id}`, { params });
};

// Получить сравнение по графику
export const getPMChartComparison = (id: number, data: { date?: string }, params?: GetRequestParams) => {
    return Http.post<ITransformedValue<IPMReportDeadlinesTask[]>>(
        `/project-management/charts/${id}/comparation/`,
        { data },
        { params },
    );
};

export const updatePMChart = (
    id: number,
    data: {
        name: string;
        managerId: number | null;
        designerId: number | null;
        calendarType: number | null;
        projectId: number | null;
        startedAt: string;
        type: number | null;
    },
) => {
    return Http.put<ITransformedValue<IPMChart>>(`/project-management/charts/${id}`, { ...data });
};

export const getPMChartHistory = <D = IPMChartHistory>(chartId: number, params?: GetRequestParams) =>
    Http.get<ITransformedValue<D[]>>(`project-management/charts/${chartId}/history`, { params });

export const getPMChartHistoryDpcQuart = <D = IPMChartHistory>(chartId: number, params?: GetRequestParams) =>
    Http.get<ITransformedValue<D[]>>(`project-management/detailed-schedule/${chartId}/history`, { params });

export const getPMChartHistoryDpcExec = <D = IPMChartHistory>(chartId: number, params?: GetRequestParams) =>
    Http.get<ITransformedValue<D[]>>(`project-management/detailed-schedule-execution/${chartId}/history`, { params });

export const getPMChartHistoryBpc = <D = IPMChartHistory>(chartId: number, params?: GetRequestParams) =>
    Http.get<ITransformedValue<D[]>>(`project-management/base-schedule/${chartId}/history`, { params });

// Получить список графиков по городам
export const getPMChartsByCity = (params?: GetRequestParams) => {
    return Http.get<ITransformedValue<IPMCity[]>>(`/project-management/charts-by-city/`, { params });
};

// Получить список проектов с статусами по графикам
export const getPMProjectsWithChartsStatus = (params?: GetRequestParams) => {
    return Http.get<ITransformedValue<IPMProject[]>>(`/project-management/projects-with-charts-status/`, { params });
};

/*******************
 * PM Chart Version
 *******************/
// Создать версию графика
export const createPMChartVersion = (data: { chartId: number; name: string; description: string }) => {
    return Http.post<ITransformedValue<IPMChartVersion>>(`/project-management/chart-versions`, { ...data });
};

// Изменить версию графика
export const updatePMChartVersion = (id: number, data: { name: string; description?: string }) => {
    return Http.put<ITransformedValue<IPMChartVersion>>(`/project-management/chart-versions/${id}`, { ...data });
};

// Копировать версию графика
export const copyPMChartVersion = (id: number, params?: GetRequestParams) => {
    return Http.get<ITransformedValue<IPMChartVersion>>(`/project-management/chart-versions/${id}/copy`, { params });
};

// Перенести данные из одной версии в другую версию графика
export const transferPMChartVersion = (fromId: number, toId: number, params?: GetRequestParams) => {
    return Http.get<ITransformedValue<IPMChartVersion>>(
        `/project-management/chart-versions/${fromId}/transfer/${toId}`,
        { params },
    );
};

// Получить версию графика
export const getPMChartVersion = (id: number) => {
    return Http.get<ITransformedValue<IPMChartVersion>>(`/project-management/chart-versions/${id}`);
};

// Удалить версию
export const deletePMChartVersion = (id: number) => {
    return Http.delete(`/project-management/chart-versions/${id}`);
};

/*********************
 * PM Detail Plan Chart
 *********************/
// Получить список детальных план-графиков по графику
export const getPMChartDpcList = (id: number) => {
    return Http.get<ITransformedValue<IPMChartDpc[]>>(
        `/project-management/chart/${id}/detailed-schedules?orderBy=created_at&sortedBy=desc`,
    );
};

// Создать детальный план-графиков по графику
export const createPMChartDpc = (id: number) => {
    return Http.get<ITransformedValue<IPMChartDpc>>(`/project-management/chart/${id}/detailed-schedules/create`);
};

// Удалить детальный план график
export const deletePMChartDpc = (id: number) => {
    return Http.delete(`/project-management/detailed-schedule/${id}/`);
};

// Получить детальный план-график
export const getPMChartDpc = (id: number, params?: GetRequestParams) => {
    return Http.get(`/project-management/detailed-schedule/${id}/`, { params });
};

// Изменить детальный план график
export const updatePMChartDpc = (
    id: number,
    data: {
        mainEngineerId?: number;
        branchDirectorId?: number;
        regionalHeadId?: number;
        constructionDirectorDeputyId?: number;
        generalDirectorDeputyId?: number;
        startDate?: string;
        endDate?: string;
    },
) => {
    return Http.put<ITransformedValue<IPMChartDpc>>(`/project-management/detailed-schedule/${id}/`, { ...data });
};

// Не согласовать детальный план-график
export const discardPMChartDpc = (id: number) => {
    return Http.get(`/project-management/detailed-schedule/${id}/not-agree`);
};

// Согласовать детальный план-график
export const agreePMChartDpc = (id: number) => {
    return Http.get(`/project-management/detailed-schedule/${id}/agree`);
};

// Отправить детальный план-график на согласование
export const approvePMChartDpc = (id: number) => {
    return Http.get(`/project-management/detailed-schedule/${id}/to-approval`);
};

// Подгрузка задач из графика в детальный план график
// использовать только для формирования и переформирования отчета (создания/изменения)
export const loadTasksPMChartDpc = (id: number, params?: GetRequestParams) => {
    return Http.get<ITransformedValue<IPMChartDpc>>(`/project-management/detailed-schedule/${id}/load-tasks`, {
        params,
    });
};

/*********************************
 * PM Detail Plan Execution Chart
 ********************************/
// Получить список детальных план-графиков (исполнение) по графику
export const getPMChartDpcExecList = (id: number) => {
    return Http.get<ITransformedValue<IPMChartDpcExec[]>>(
        `/project-management/chart/${id}/detailed-schedules-execution?orderBy=created_at&sortedBy=desc`,
    );
};

// Создать детальный план-график (исполнение) по графику
export const createPMChartDpcExec = (id: number) => {
    return Http.get<ITransformedValue<IPMChartDpcExec>>(
        `/project-management/chart/${id}/detailed-schedules-execution/create`,
    );
};

// Удалить детальный план график (исполнение)
export const deletePMChartDpcExec = (id: number) => {
    return Http.delete(`/project-management/detailed-schedule-execution/${id}/`);
};

// Получить детальный план-график (исполнение)
export const getPMChartDpcExec = (id: number, params?: GetRequestParams) => {
    return Http.get(`/project-management/detailed-schedule-execution/${id}/`, { params });
};

// Изменить детальный план график (исполнение)
export const updatePMChartDpcExec = (
    id: number,
    data: {
        mainEngineerId?: number;
        branchDirectorId?: number;
        regionalHeadId?: number;
        constructionDirectorDeputyId?: number;
        generalDirectorDeputyId?: number;
        detailedScheduleId?: number;
    },
) => {
    return Http.put<ITransformedValue<IPMChartDpcExec>>(`/project-management/detailed-schedule-execution/${id}/`, {
        ...data,
    });
};

// Изменить задачу детального плана графика (исполнение)
export const updateTaskPMChartDpcExec = (id: number, data: { description: string | null }) => {
    return Http.put<ITransformedValue<IPMChartDpcExecTask>>(
        `/project-management/detailed-schedule-execution-task/${id}/`,
        {
            ...data,
        },
    );
};

// Не согласовать детальный план-график (исполнение)
export const discardPMChartDpcExec = (id: number) => {
    return Http.get(`/project-management/detailed-schedule-execution/${id}/not-agree`);
};

// Согласовать детальный план-график (исполнение)
export const agreePMChartDpcExec = (id: number) => {
    return Http.get(`/project-management/detailed-schedule-execution/${id}/agree`);
};

// Отправить детальный план-график (исполнение) на согласование
export const approvePMChartDpcExec = (id: number) => {
    return Http.get(`/project-management/detailed-schedule-execution/${id}/to-approval`);
};

// Подгрузка задач из графика в детальный план график (исполнение)
// использовать только для формирования и переформирования отчета (создания/изменения)
export const loadTasksPMChartDpcExec = (id: number, detailedScheduleId: number, params?: GetRequestParams) => {
    return Http.post<ITransformedValue<IPMChartDpcExec>>(
        `/project-management/detailed-schedule-execution/${id}/load-tasks`,
        {
            detailedScheduleId,
        },
        {
            params,
        },
    );
};

/*********************
 * PM Base Plan Chart
 *********************/
// Получить список базовых план-графиков по графику
export const getPMChartBpcList = (id: number) => {
    return Http.get<ITransformedValue<IPMChartBpc[]>>(
        `/project-management/chart/${id}/base-schedules?orderBy=created_at&sortedBy=desc`,
    );
};

// Создать базовый план-графиков по графику
export const createPMChartBpc = (id: number) => {
    return Http.get<ITransformedValue<IPMChartBpc>>(`/project-management/chart/${id}/base-schedules/create`);
};

// Удалить базовый план график
export const deletePMChartBpc = (id: number) => {
    return Http.delete(`/project-management/base-schedule/${id}/`);
};

// Получить базовый план-график
export const getPMChartBpc = (id: number, params?: GetRequestParams) => {
    return Http.get(`/project-management/base-schedule/${id}/`, { params });
};

// Изменить базовый план график
export const updatePMChartBpc = (
    id: number,
    data: {
        mainEngineerId?: number;
        branchDirectorId?: number;
        regionalHeadId?: number;
        constructionDirectorDeputyId?: number;
        generalDirectorDeputyId?: number;
    },
) => {
    return Http.put<ITransformedValue<IPMChartBpc>>(`/project-management/base-schedule/${id}/`, { ...data });
};

// Не согласовать базовый план-график
export const discardPMChartBpc = (id: number) => {
    return Http.get(`/project-management/base-schedule/${id}/not-agree`);
};

// согласовать базовый план-график
export const agreePMChartBpc = (id: number) => {
    return Http.get(`/project-management/base-schedule/${id}/agree`);
};

// Отправить базовый план-график на согласование
export const approvePMChartBpc = (id: number) => {
    return Http.get(`/project-management/base-schedule/${id}/to-approval`);
};

// Подгрузка задач из графика в базовый план график
// использовать только для формирования и переформирования отчета (создания/изменения)
export const loadTasksPMChartBpc = (id: number, params?: GetRequestParams) => {
    return Http.get<ITransformedValue<IPMChartBpc>>(`/project-management/base-schedule/${id}/load-tasks`, {
        params,
    });
};
// Получить PDF
export const getPdfPMPlanChart = (id: number, type: string): Promise<AxiosResponse<ITransformedValue<string>>> => {
    return Http.get(`/project-management/${type}/${id}/pdf`);
};

export const getMaterials = async (params?: GetRequestParams) => {
    const response = await Http.get(`/materials`, { params });
    return response.data.data;
};

export const updateEstimateRow = async (id: number, data: IEstimatePositionUpdateModel) => {
    const response = await Http.post(`/estimate/string/change/${id}`, data);
    return response.data.data;
};

export const getEstimateHistory = <D = History>(id: number, params?: GetRequestParams) =>
    Http.get<ITransformedValue<D[]>>(`/estimates/${id}/history`, { params });

/**
 * Models
 */
export const getModelFilters = async () => (await Http.get<IDesignFilters>('/designs/filters/get')).data;

export const getModels = async (params?: GetRequestParams) => {
    const response = await Http.get<ITransformedValue<Design[]>>(`/design-models`, { params });
    return response.data;
};

export const getModel = (id: number | string): Promise<AxiosResponse<ITransformedValue<Design>>> =>
    Http.get<ITransformedValue<Design>>(
        `/design-models/${id}?include=project.users,project.generalDesigners,drawings,author`,
    );

export const getChartVersionStructure = <D = ChartVersion>(
    id: number,
    params: GetRequestParams = { include: 'tasks, executor' },
) => Http.get<ITransformedValue<D>>(`/project-management/chart-versions/${id}`, { params });

export const createTaskStructure = (data: CreateTaskStructureBody) => {
    return Http.post(`/project-management/chart-versions/tasks`, data);
};

export const copyTaskStructure = (id: number) => {
    return Http.get(`/project-management/chart-versions/tasks/${id}/copy`);
};

export const updateTaskStructure = (id: number, data: CreateTaskStructureBody) => {
    return Http.put(`/project-management/chart-versions/tasks/${id}`, data);
};

export const deleteTaskStructure = (id: number) => {
    return Http.delete(`/project-management/chart-versions/tasks/${id}`);
};

export const getTasksByVersionStructure = <D = Structure[]>(
    id: number,
    params: GetRequestParams = { search: 'type:2' },
) => Http.get<ITransformedValue<D>>(`/project-management/chart-versions/${id}/tasks`, { params });

export const updateConnectionStructure = (id: number, data: { isAutoChange: boolean; manualStartDate?: string }) => {
    return Http.put(`/project-management/chart-versions/tasks/${id}/change-links`, data);
};

export const moveTaskStructure = (
    id: number,
    data: { code: number; chartVersionId: number; parentTaskId?: number },
) => {
    return Http.put(`/project-management/chart-versions/tasks/${id}/change-position`, data);
};

export const createLinkStructure = (data: {
    type: number;
    sourceTaskId: number;
    targetTaskId: number;
    delay?: number;
}) => {
    return Http.post(`/project-management/chart-version-tasks/link`, data);
};

export const updateLinkStructure = (
    id: number,
    data: {
        type: number;
        sourceTaskId: number;
        targetTaskId: number;
        delay?: number;
    },
) => {
    return Http.put(`/project-management/chart-version-tasks/link/${id}`, data);
};

export const getTaskByIdStructure = <D = Structure>(id: number) =>
    Http.get<ITransformedValue<D>>(`/project-management/chart-version-tasks/${id}`);

export const deleteLinkStructure = (id: number) => {
    return Http.delete(`/project-management/chart-version-tasks/link/${id}`);
};

export const updateProgressStructure = (
    id: number,
    data: {
        executorId?: number;
        completeness?: number;
        duration?: number;
        comment?: string;
        stopPeriodStart?: string;
        stopPeriodEnd?: string;
    },
) => {
    return Http.put(`/project-management/chart-versions/tasks/${id}/change-progress`, data);
};

export const getHistoryStructure = <D = IPMChartHistory[]>(id: number) =>
    Http.get<ITransformedValue<D>>(`/project-management/chart-version-tasks/${id}/history`);

export const getUsersStructure = <D = User[]>() => Http.get<ITransformedValue<D>>(`/project-management/users`);

export const removeWarningStructure = (id: number) => {
    return Http.get(`/project-management/chart-version-tasks/${id}/read`);
};

export const getTemplatesStructure = <D = TemplateStructure[]>() => {
    return Http.get<ITransformedValue<D>>(`/project-management/chart-version-temples`);
};

export const applyTemplateStructure = (versionId: number, templateId: number) => {
    return Http.get(`/project-management/chart-version-temples/change/from/${versionId}/to/${templateId}`);
};
